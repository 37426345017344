[class^="icon-"],
[class*=" icon-"] {
    display: inline-block;
    width: 16px;
    height: 16px;
    line-height: 14px;
    vertical-align: text-top;
    background-repeat: no-repeat;
    background-size: 16px 16px;
}

.icon-arrow-left,
.icon-left {
    background-image: url(../images/icons_app/navigate_left_16.png);
}

.icon-arrow-right,
.icon-right {
    background-image: url(../images/icons_app/navigate_right_16.png);
}

.icon-up {
    background-image: url(../images/icons_app/navigate_up_16.png);
}

.icon-down {
    background-image: url(../images/icons_app/navigate_down_16.png);
}

.icon-list {
    background-image: url(../images/icons_app/list_16.png);
}

.icon-point-list {
    background-image: url(../images/icons_app/list_point_16.png);
}

.icon-home {
    background-image: url(../images/icons_app/home_16.png);
}

.icon-play {
    background-image: url(../images/icons_app/play_16.png);
}

.icon-stop {
    background-image: url(../images/icons_app/stop_16.png);
}

.icon-change {
    background-image: url(../images/icons_app/refresh_16.png);
}

.icon-refresh {
    background-image: url(../images/icons_app/refresh_16.png);
}

.icon-new {
    background-image: url(../images/icons/stdi_004.png);
}

.icon-plus {
    background-image: url(../images/icons_app/plus_16.png);
}

.icon-minus {
    background-image: url(../images/icons_app/minus_16.png);
}

.icon-spesen {
    background-image: url(../images/icons_app/spesen_16.png);
}

.icon-time-split {
    background-image: url(../images/icons_app/time_split_16.png);
}

.icon-time-follow {
    background-image: url(../images/icons_app/plus_16.png); /* TODO: PLACEHOLDER */
}

.icon-time-change {
    background-image: url(../images/icons/stdi_344.png);
}

.icon-copy {
    background-image: url(../images/icons_app/copy_16.png);
}

.icon-edit {
    background-image: url(../images/icons/stdi_005.png);
}

.icon-cancel {
    background-image: url(../images/icons/stdi_016.png);
}

.icon-delete {
    background-image: url(../images/icons/stdi_006.png);
}

.icon-remove {
    background-image: url(../images/icons_app/garbage_16.png);
}

.icon-close {
    background-image: url(../images/icons_app/cancel_16.png);
}

.icon-save {
    background-image: url(../images/icons_app/save_16.png);
}

.icon-hide {
    background-image: url(../images/icons_app/visible_off_16.png);
}

.icon-show {
    background-image: url(../images/icons_app/visible_on_16.png);
}

.icon-fav-on {
    background-image: url(../images/icons_app/favorite_on_16.png);
}

.icon-fav-off {
    background-image: url(../images/icons_app/favorite_off_16.png);
}

.icon-followpart {
    background-image: url(../images/icons_app/follow_part_16.png);
}

.icon-followtable {
    background-image: url(../images/icons_app/table_16.png);
}

.icon-search-delete {
    background-image: url(../images/icons_app/search_delete_16.png);
}

.icon-search {
    background-image: url(../images/icons_app/search_16.png);
}

.icon-search-quick {
    background-image: url(../images/icons_app/search_quick_16.png);
}

.icon-logic-smaller {
    background-image: url(../images/icons_app/logic_text_smaller_grey_16.png);
}

.icon-logic-greater {
    background-image: url(../images/icons_app/logic_text_bigger_grey_16.png);
}

.icon-logic-smallerequal {
    background-image: url(../images/icons_app/logic_text_smaller_equal_grey_16.png);
}

.icon-logic-greaterequal {
    background-image: url(../images/icons_app/logic_text_bigger_equal_grey_16.png);
}

.icon-logic-notequal {
    background-image: url(../images/icons_app/logic_text_unequal_grey_16.png);
}

.icon-logic-or {
    background-image: url(../images/icons_app/logic_text_or_gray_16.png);
}

.icon-logic-and {
    background-image: url(../images/icons_app/logic_text_and_gray_16.png);
}

.icon-logic-lbr {
    background-image: url(../images/icons_app/logic_text_bracket_left_grey_16.png);
}

.icon-logic-rbr {
    background-image: url(../images/icons_app/logic_text_bracket_right_gray_16.png);
}

.icon-print {
    background-image: url(../images/icons/stdi_007.png);
}

.icon-calendar {
    background-image: url(../images/icons_app/calendar_16.png);
}

.icon-numeric {
    background-image: url(../images/icons_app/fieldtype_number_inactive_16.png);
}

.icon-text {
    background-image: url(../images/icons_app/fieldtype_text_inactive_16.png);
}

.icon-mc-select {
    background-image: url(../images/icons_app/fieldtype_check_inactive_16.png);
}

.icon-radio {
    background-image: url(../images/icons_app/fieldtype_radio_inactive_16.png);
}

.icon-check-on {
    background-image: url(../images/icons_app/check_on_16.png);
}

.icon-check-off {
    background-image: url(../images/icons_app/check_off_16.png);
}

.icon-clock {
    background-image: url(../images/icons_app/clock_16.png);
}

.icon-cogwheel {
    background-image: url(../images/icons_app/settings_16.png);
}

.icon-checkout {
    background-image: url(../images/icons/stdi_141.png);
}

.icon-checkin {
    background-image: url(../images/icons/stdi_142.png);
}

.icon-msg {
    background-image: url(../images/icons/stdi_045.png);
}

.icon-report {
    background-image: url(../images/icons/stdi_007.png);
}

.icon-folder-open {
    background-image: url(../images/icons/stdi_342.png);
}

.icon-folder-closed {
    background-image: url(../images/icons/stdi_343.png);
}


.icon-ms-excel {
    background-image: url(../images/icons/stdi_501.png);
}

.icon-ms-word {
    background-image: url(../images/icons/stdi_500.png);
}

.icon-notify-close {
    background-image: url(../images/icons/stdi_155.png);
}

.icon-notify-reject {
    background-image: url(../images/icons/stdi_024.png);
}

.icon-notify-cancel {
    background-image: url(../images/icons/stdi_009.png);
}

.icon-program {
    background-image: url(../images/icons/stdi_000.png);
}

.icon-planviewer {
    background-image: url(../images/icons/stdi_561.png);
}

.icon-sort {
    background-image: url(../images/icons/stdi_948.png);
}

.icon-person {
    background-image: url(../images/icons/stdi_157.png);
}

.icon-mobile-phone {
    background-image: url(../images/icons/stdi_775.png);
}

.icon-office-phone {
    background-image: url(../images/icons/stdi_225.png);
}

.icon-email {
    background-image: url(../images/icons/stdi_008.png);
}

.icon-website {
    background-image: url(../images/icons/stdi_103.png);
}

.icon-fax {
    background-image: url(../images/icons/stdi_656.png);
}

.icon-notes {
    background-image: url(../images/icons/stdi_551.png);
}